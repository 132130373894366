export default {
  home: {
    minute: '分钟',
    price_info: '{mianfei}分钟内免费，日封顶{currencyName}{fengding}，押金{currencyName}{yajin}，支持免押金租借',
    feature_1: '四种充电方式，适配所有手机',
    feature_2: '全国通借通还',
    feature_3: '特别为无线充电设计',
    submit: '立即充值',
    borrow: '立即租借',
    msg_fail_title: '提示',
    msg_fail_content: '租借失败，请重新尝试',
    msg_rentcount: '你当前有{zujieNum}个订单未结束',
    inputcodetitle: '请输入机柜上的设备号',
    cancel: '取消',
    confirm: '确定',
    shoptime: '营业时间',
    supportrental: '可归还',
    supportreturn: '可租借',
    useragreen: '点击同意“委托借记授权表”、“用户协议”、“充值协议”',
    privacyagreen: '点击同意隐私协议',
    offline: '设备 {devicecode} 离线，请与在场人员联系',
    permissionfail: '权限被拒绝。',
    unbound: '二维码 {devicecode} 未绑定到设备',
    nobattery: '设备｛devicecode｝中没有可用的电池'
  },
  loginborrow: {
    msg_fail_title: '提示',
    msg_fail_user: '获取用户信息出错，是否重试？',
    msg_rentcount: '你当前有{zujieNum}个订单未结束',
    msg_fail_support: '当前环境不支持',
    msg_fail_confirm: '确定',
    msg_fail_retry: '重试',
    msg_fail_cancel: '取消',
    msg_fail_order: '创建租借订单失败',
    msg_fail_paymod: '获取支付方式失败',
    msg_fail_login: '登录失败',
    powerbanktitle: '解锁возьми заряд充电宝',
    Apple: 'Apple',
    otheropt: '其他方式',
    selectmethod: '请选择支付方式',
    paymentdetail: '付款方式详情',
    couponcode: '优惠码',
    rent: '租借',
    pricing: '查看价格',
    phone: '手机号',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: '使用 Facebook 登录',
    logingg: '使用 Google 登录',
    captcha_code: '验证码',
    phonenumber: '请输入你的手机号',
    msg_fail_code: '请输入验证码 ',
    msg_fail_phone: '请输入您的电话号码',
    logout: '退出账号',
    savenow: '立即保存',
    delete: '删除',
    addcard: '卡包',
    msg_fail_content: '租借失败，请重新尝试',
    title: '请确认授权',
    policy: 'Policy.',
    term: '条款和条件',
    and: '以及',
    privacy: '隐私政策.',
    tips: '继续，则您同意我们的',
    cancel: '取消',
    agree: '同意'
  },
  preauth: {
    preauthortitle: '关于预授权',
    tip1: '开始租赁时，您的付款方式会得到预先授权。这只是为了确保有足够的资金来支付您的租金。',
    tip2: '您的银行可能会向您发送通知，但这不是收费：只会收取您的租金。',
    tip3: '根据您的银行，可能需要几个小时到几天的时间才能在您的对账单上调整金额，这是正常的。'
  },
  rentcost: {
    rentcosttitle: '租金是多少？',
    tip1: '如果您在2天内没有归还充电宝，除了租赁费外，还将向您收取{currency} {yajin} 的归还费。',
    tip2: '持续时间四舍五入到最接近的半小时（30或60分钟）。',
    tip3: '1天租金从租赁开始算起24小时。',
    tip3_1: '您的付款方式将收取{currency}{currencyName} {cyajin}的预授权费。这是为了确保有足够的资金支付租金',
    tip3_2: '您的银行可能会向您发送通知，但这不是收费，只收取租金',
    tip3_3: '根据您的银行，可能需要几个小时，最多几天的时间才能在您的对账单上调整金额',
    tip4: '一些场馆可能会提供一些免费时间或折扣',
    Days: '天',
    Hours: '小时',
    Minutes: '分钟'
  },
  logincode: {
    login: '登录',
    retry: '重试',
    title: '验证码',
    subtitle: '请输入我们发送给您的电话号码的验证码'
  },
  agreenconfirm: {
    title: '请确认授权',
    policy: 'Policy.',
    term: 'возьми заряд的条款和条件',
    and: '以及',
    privacy: '隐私政策.',
    tips: 'l agree to ',
    cancel: '取消',
    agree: '同意'
  },
  users: {
    username: '用户名',
    mobile: '电话',
    login: '登录',
    orders: '租借订单',
    wallet: '钱包',
    joinus: '合作加入',
    langsetting: '语言设置'
  },
  login: {
    phonenumber: '手机号',
    verificationcode: '验证码',
    Rentpowerbank: '租借充电宝',
    Rentpowerbanktip: '输入您的手机号码以开始',
    phone_number: '请输入{0}位数手机号码',
    captcha_code: '验证码',
    send_code: '获取验证码',
    login: '登录',
    agree: '点击登录，表示已阅读并同意',
    readme: '使用条款',
    msg_fail_login: '登录失败',
    msg_success_login: '登录成功',
    msg_success_login_code: '領取成功，請下載APP登錄使用',
    msg_fail_user: '获取用户信息出错，是否重试？',
    no_sms: '没收到短信',
    retry: '重新发送',
    receive: '申请领取',
    invite_pwd: '邀请验证码',
    vip_agreen_tip: '继续，表示您接受我们的',
    vip_agreen_service: '服务条款',
    vip_agreen_and: '和',
    vip_agreen_policy: '隐私政策'
  },
  confirm: {
    title: '确认付款',
    pay_label: '需支付',
    addcard: '添加卡包',
    paynow: "立即付款",
    credit_card: "信用卡",
    price_tips: '点击确认付款，表示已阅读并同意《增值协议》',
    pay_type: '请选择支付方式',
    submit: '确认租借',
    charge: '确认充值',
    msg_fail_title: '提示',
    msg_fail_order: '创建租借订单失败',
    msg_fail_confirm: '确定',
    msg_fail_retry: '重试',
    msg_fail_cancel: '取消'
  },
  join: {
    title: '商户合作',
    call: '拨打',
    tips: '请留下您的基础信息，我们会尽快联系您！',
    type1: '装机合作',
    type2: '广告合作',
    type3: '其他',
    shop_name: '商家名称',
    contact_person: '联络人',
    phone: '联系电话',
    submit: '提交',
    noempty: '不能为空'
  },
  mine: {
    title: '个人中心',
    wallet: '我的钱包',
    balance: '我的余额',
    mywalletcard: '我的卡包',
    addcard: '添加卡包',
    deposit: '押金',
    wallet_in: '充值',
    wallet_out: '提现',
    wallet_log: '充值提现',
    order: '租借订单',
    login_tips: '请先登录',
    msg_fail_title: '提示',
    msg_fail_user: '获取用户信息出错，是否重试？',
    help: '帮助',
    setting: '设置',
    logout: '退出',
    unpaid: '未繳納',
    using: '使用中'
  },
  msg: {
    nopaymode: '支付方式获取失败',
    unlogin: '你还未登录，请登录',
    msg_fail_title: '提示',
    deletecard: '确认删除该卡吗？',
    loading: '加载中...',
    title: '提示',
    success: '操作成功',
    error: '未知异常，请联系管理员',
    userinfo_fail: '用户信息失效，请重新登录',
    roleerror: '权限不足，请咨询管理员',
    neterror: '网络连接失败，请稍后再试',
    confirm: '确认',
    retry: '重试',
    cancel: '取消',
    msg_logout: '确认退出账号？',
    addorselectcard: '请添加或选择一个卡包后继续',
    vieworder: '查看订单',
    success_payment: '充值成功',
    fail_payment: '付款失败',
    keyword: '关键词',
    unpaid_order: '有未付款的订单',
    go_pay: '去支付',
    no_more: '沒有更多了',
    noshop: '暂无商户',
    msg_fail_code: '请输入验证码',
    msg_fail_phone: '请输入手机号',
    exceedorder: '你已经有租借中订单，请归还后继续'
  },
  result: {
    title: '租借成功',
    tips: '要查看订单和其他возьми заряд位置，请前往首页查看更多。',
    download: '下载',
    vieworder: '查看订单',
    msg_tips_loading: '正在弹出充电宝，请耐心等待...',
    msg_result_loading: '正在确认支付结果...',
    haveorder: '正式订单已生成'
  },
  result_msg: {
    loading: '加载中...',
    refresh: '点击刷新'
  },
  order: {
    title: '租借订单',
    in_use: '正在使用',
    ended: '已结束',
    msg_error: '加载失败',
    no_data: '暂无数据'
  },
  order_item: {
    order_number: '订单号',
    venue_rented: '租借地点',
    time_rented: '租借时间',
    venue_returned: '归还地点',
    time_returned: '归还时间',
    charge_standard: '收费标准',
    rent_occurred: '租借费用',
    returned: '已归还',
    unreturned: '未归还',
    type_0: '未租借',
    type_1: '租借中',
    type_2: '已撤销',
    type_3: '已归还',
    type_4: '有疑问',
    type_5: '超时归还',
    min: '分',
    unpaid: '未付款',
    payment: '付款'
  },
  setting: {
    title: '设置',
    lang: '选择语言'
  },
  help: {
    title: '帮助'
  },
  wallet_in: {
    title: '增值',
    price_label: '请选择其他充值金额',
    price_placeholder: '請輸入增值金額',
    balance: '當前結餘',
    chargemoney: '请选择充值金额',
    submit: '确定充值',
    loading: '加載中...',
    msg_title: '提示'
  },
  wallet_out: {
    title: '余额提现',
    price_label: '请输入取款金额',
    price_placeholder: '请输入提现金额',
    balance: '当前余额',
    balance_all: '全部提取',
    deposit: '押金',
    deposit_tips: '目前有租借中的订单，押金{pcurrencyName}{pyajin}暂不可退',
    submit: '立即提现',
    apply: '申请提现',
    confirm: '确认',
    loading: '加载中...',
    msg_fail_price: '提现金额不正确',
    msg_fail_balance: '提现金额不能大于当前余额',
    msg_title: '错误',
    msg_success_content: '申请提现成功',
    tips_title: '说明',
    btn: '提交',
    error_tips: '请输入完整的信息'
  },
  wallet_card: {
    savecard: 'Save Card',
    delete: 'Delete',
    addcard: 'Enter card details'
  },
  map: {
    在线: "在线",
    离线: "离线",
    title: "附近设备",
    result_msg: "附近未找到相关设备",
    msg_title: "错误",
    no_support: "抱歉！您的浏览器不支持定位",
    my_position: "我的位置",
    distance: "距你",
    shop_time: "营业时间",
    free: "可借",
    return: "可还",
    shop_title: "商家详情",
    address: "地址",
    go_here: "去这里"
  }
}
