// doc: https://developers.cloudpayments.ru/#ustanovka-vidzheta

import {
  CLOUD_PAYMENT_PAYORDER,
  CLOUD_PAYMENT_AUTH_PAYMENT
} from "../apis/payment"

export default {
  methods: {
    // 加载CloudPayments
    initCloudPayments_old() {
      console.log("CloudPayments import")
      let oscript = window.document.createElement("script")
      oscript.src = `https://checkout.cloudpayments.ru/checkout.js`
      oscript.onerror = (error) => {
        console.log("CloudPayments load fail", error)
      }
      oscript.onload = () => {
        console.log("CloudPayments load success")
      }
      let head =
        window.document.head ||
        window.document.getElementsByTagName("head")[0] ||
        window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    },
    // 加载CloudPayments
    initCloudPayments() {
      console.log("CloudPayments import")
      let oscript = window.document.createElement("script")
      oscript.src = `https://widget.cloudpayments.ru/bundles/cloudpayments.js`
      oscript.onerror = (error) => {
        console.log("CloudPayments load fail", error)
      }
      oscript.onload = () => {
        console.log("CloudPayments load success")
      }
      let head =
        window.document.head ||
        window.document.getElementsByTagName("head")[0] ||
        window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    },
    // 支付CloudPayments
    async payCloudPayment(payment, callBack) {
      if (!cp) {
        return setTimeout(() => this.payCloudPayment(callBack), 1000)
      }
      var widget = new cp.CloudPayments({ language: payment.lang })
      const payParam = await this.authCloudPayment(payment)
      // options
      const payOption = {
        publicId: payParam.publicId, // id
        amount: parseFloat(payParam.totalFee),
        currency: payParam.currency,
        invoiceId: payParam.outTradeNo,
        description: payParam.subjectName,
        // accountId: 'user@example.com',
        skin: "mini",
        autoClose: 6
      }
      const payCallback = {
        onSuccess: function (options) { // success
          options.outTradeNo = payParam.outTradeNo
          callBack && callBack(options)
        },
        onFail: function (reason, options) { // fail
          callBack && callBack({ error: reason, code: 1, options })
          console.log("onFail reason:", reason, "options:", options)
        },
        onComplete: function (paymentResult, options) {
          console.log("onComplete paymentResult:", paymentResult, "options:", options)
        }
      }
      widget.pay('auth', payOption, payCallback) // или 'charge'
    },
    // 获取支付参数
    async authCloudPayment(payment = {}) {
      try {
        let rentUrl = `${CLOUD_PAYMENT_AUTH_PAYMENT}${payment.qrcode}/${payment.pId}?checkoutPay=true`
        const headers = {
          headers: { "Content-Type": "application/json;charset=UTF-8" }
        }
        const query = { agentld: payment.agentld }
        if (payment.payType === "FK") {
          rentUrl = `${CLOUD_PAYMENT_PAYORDER + payment.pId}`
          query.invoiceId = payment.orderId
        }
        const result = await this.$Request("post", rentUrl, query, headers)
        let payResult = (result && result.data) || {}
        if (payment.payType === "FK") payResult.outTradeNo = result.data.pOrderid
        console.log(`cloudPayment ${rentUrl}~ payResult:`, payResult)
        return payResult
      } catch (err) {
        this.$loading(false)
        console.warn("cloudPayment ~ err:", err)
      }
    }
  }
}
